import Stream from './stream'

export default (() => {
  class StreamController {
    constructor({ selector }) {
      this.streams = [...document.querySelectorAll(selector)]
      if (!this.streams) return
      this.streamInstances = this.streams.forEach(s => new Stream(s, this))
    }
  }

  return StreamController
})()
