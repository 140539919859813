import geminiAdRenderer from '../../../renderers/geminiStreamRow'

const NATIVE_JS = 'https://s.yimg.com/dy/ads/native.js'
const DEFAULT_API_KEY = 'C5VPNN3HH87ZR8M33TBQ'
const DEFAULT_SLOTS = [0, 3, 6, 10]

export default (() => {
  class GeminiPlugin {
    constructor(elem, instance) {
      if (!elem) return
      this.elem = elem
      this.instance = instance

      this.list = this.elem.querySelector('ul')
      if (!this.list && this.list) return

      this.adUnitCode = this.elem.dataset.geminiAdunitcode
      if (!this.adUnitCode) {
        console.error(`Stream: could not find ad unit code`)
      }

      let slots = DEFAULT_SLOTS
      if (this.elem.dataset.geminiSlots) {
        try {
          slots = JSON.parse(this.elem.dataset.geminiSlots)
        } catch (e) {
          console.warn('Stream: Using default slots')
        }
      }

      this.slots = slots
      this.apiKey = this.elem.dataset.geminiApikey || DEFAULT_API_KEY

      this.init()
    }

    init() {
      window.apiKey = this.apiKey
      const script = document.createElement('script')
      script.async = true
      script.src = NATIVE_JS
      document.body.appendChild(script)

      this.slots.forEach(slotIdx => this.insertAdIntoSlot(slotIdx))
    }

    insertAdIntoSlot(slotIdx) {
      const geminiAd = geminiAdRenderer(this.adUnitCode)
      this.list.insertBefore(geminiAd, this.list.childNodes[slotIdx])

      window.native = window.native || []
      window.native.push(this.adUnitCode)
    }
  }

  return GeminiPlugin
})()
